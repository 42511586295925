import { replace } from "connected-react-router"
import Cookies from "js-cookie"
import queryString from "query-string"
import { all, call, put, select } from "redux-saga/effects"

import api from "apis"
import { addAlert } from "app-store-v2/app/actions"
import { submit_ga_tracker } from "helpers/analytics-helpers"
import { perfMark, isTestMode } from "helpers/application"
import { getProductPrefs } from "helpers/user-prefs"

import {
  updateImages,
  addCoupon,
  addWizardData,
  clearChoices,
  deactivatePrefStore,
  forcePanelIndex,
  loadDefaults,
  scrollPanelIndex,
  setExtraWizardParams,
  setSignatureBasket,
  clearEditFormUrl,
  setWizardHeader,
  setWizardLoading,
  setWizardPrefOrder,
  updateChoice,
  updateShipping,
  replaceWizardData,
  productNotFound,
  productFound,
  resetPaneFilters,
  fetchStylecode,
} from "../actions"

export default function* loadWizard(action) {
  perfMark("product:load_wizard:start")
  const state = yield select()
  const styleCode = action.data.style_code
  const parsedQuery = queryString.parse(window.location.search)
  const ssr = state.router.action == "POP"
  const is_flip_sku = Object.keys(state.wizard.data).find((k) =>
    k.includes("face")
  )

  // We parse the query string for parameters that can override the product/user prefs
  let qs = (function (a) {
    if (a == "") return {}
    var b = {}
    for (var i = 0; i < a.length; ++i) {
      var p = a[i].split("=")
      if (p.length != 2) continue
      b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "))
    }
    return b
  })(window.location.search.substr(1).split("&"))
  // This invokes the "Update Cart" by setting the line_item_id in the store
  if (qs.line_item_id) {
    yield put(setExtraWizardParams({ lid: qs.line_item_id }))
  }
  // This ensures that the user pref selected box is not set to the user prefs when edit item is invoked
  // (passed from the Edit Line Item URL as a parameter
  if (qs.nobox) {
    yield put(setExtraWizardParams({ nobox: true }))
  }
  // This opens accordion panel and scrolls to it, if panel is present in query
  if (qs.panel) {
    yield put(forcePanelIndex(qs.panel))
    yield put(scrollPanelIndex(qs.panel))
  }
  // This sets today so we can test inventory for subsequent calls within update-price-shipping-time
  if (qs.today) {
    yield put(setExtraWizardParams({ today: qs.today }))
  }
  if (qs.time) {
    yield put(setExtraWizardParams({ time: qs.time }))
  }
  // This gets the product-specific user preferences for any particular style code
  let localPrefs = getProductPrefs(styleCode)
  let userPrefs = Cookies.getJSON("userPreferences") || {}
  try {
    const userPrefValues = Object.values(userPrefs).join("")
    const test = encodeURIComponent(userPrefValues) // eslint-disable-line no-unused-vars
  } catch (e) {
    // userPrefs have malformed characters, so we reset and start over with them
    Cookies.remove("userPreferences")
    userPrefs = {}
  }

  // Special flag, checks if engravings have been provided through query string or by user, and skips
  // using the default engravings in this particular case.
  let skipDefaultEngravings =
    Object.keys(localPrefs).find((k) => k.match(/^e[0-9]+$/)) ||
    Object.keys(userPrefs).find((k) => k.match(/^e[0-9]+$/)) ||
    Object.keys(qs).find((k) => k.match(/^e[0-9]+$/))

  // This fetches the remaining product defaults. We pass the values from qs so valid values for the product are
  // returned
  // The actual fetch occurs after the prefStore is loaded, unless we are in switchMode
  let params = new URLSearchParams()
  Object.keys(qs).forEach((key) => params.append(key, qs[key]))
  if (state.wizard.adminMode) {
    params.append("include_unsearchable", "true")
    params.append("from_admin", "true")
  } else {
    Object.keys(localPrefs).forEach((key) => {
      // qs trumps localPrefs
      if (typeof qs[key] === "undefined") {
        params.append(key, localPrefs[key])
      }
    })
  }

  // Load in the Product Wizard data before calling product_defaults
  // The data inside of the products_controller#react_product method is loaded in using the following
  // code
  let data_loads = []
  let preload = {}
  let result = {}
  let signatureBasket = false
  if (action.data.switchMode) {
    try {
      // params.append("switchMode", "1")
      if (!qs.olid) {
        yield put(clearEditFormUrl())
      }
      if (action.data.olid && qs.olid) {
        params.append("olid", action.data.olid)
      }
      const url = `/api/v10/products/${styleCode}/product_defaults?${params.toString()}`
      result = yield call(api.get, url)
      if (result?.data?.redirect) {
        yield put(replace(result.data.redirect))
        let redirect_params = new URLSearchParams()
        // redirect_params.append("switchMode", "1")
        Object.keys(result.data.override_params).forEach((key) => {
          redirect_params.append(key, result.data.override_params[key])
        })
        const redirect_url = `/api/v10/products/${
          result.data.style_code
        }/product_defaults?${redirect_params.toString()}`
        result = yield call(api.get, redirect_url)
      }

      // SPA navigation, update the PDP unique event id
      if (result?.data?.unique_event_id) {
        window.cache_free_ssr_data.unique_event_ids.pdp =
          result.data.unique_event_id
      }

      yield put(updateImages(result?.data?.product_image))

      preload = {
        pds: result.data.wizard.pds,
        product_title: result.data.wizard.header.title,
        react: result.data.wizard,
      }
      yield put(productFound())
      yield put(setWizardHeader(result.data.wizard.header))
    } catch (error) {
      console.log(error)
      return yield put(productNotFound())
    }
  } else {
    preload = {
      pds: state.choices,
      product_title: state.wizard.header.title,
      react: state.wizard,
    }
  }

  if (!isTestMode) {
    dataLayer.push({
      event: "google-remarketing",
      googleRemarketing: {
        ecomm_pagetype: "product",
        ecomm_prodid: `${styleCode}-base`,
        ecomm_totalvalue: preload.react.base_ss_price,
      },
    })
    submit_ga_tracker(`/products/${styleCode}`)
  }

  // global_store_pref_flag = false
  if (!ssr) {
    yield put(deactivatePrefStore())
  }

  if (preload.react) {
    if (preload.react.masterStyleCode) {
      yield put(replace(preload.react.base_product_url))
      yield put(fetchStylecode(preload.react.masterStyleCode, true))
      return
    }
    if (!ssr) {
      yield put(replaceWizardData(preload.react.data))
      // Need to clear existing data from wizard
      yield put(resetPaneFilters())
    }

    Object.keys(preload.react.data).forEach((key) => {
      let data = preload.react.data[key]
      if (key !== "diamond") {
        !ssr && data_loads.push(put(addWizardData(data)))
        if (data.engravings) {
          data.engravings.forEach((engraving) => {
            if (state.wizard.adminMode) {
              engraving.any_case = true
              engraving.max_chars = engraving.admin_max_chars
            }
            if (
              state.choices.sc &&
              engraving.engraving_num > state.choices.sc.value
            ) {
              engraving.disabled = true
            }
            engraving.is_default = !skipDefaultEngravings
            data_loads.push(put(addWizardData(engraving)))
          })
        }
        // TEMP: Fix Flip SKUs due to incorrect preloaded data in Redux for stone options
        if ((!ssr || is_flip_sku) && data.stones) {
          data.stones.forEach((stone) => {
            data_loads.push(put(addWizardData(stone)))
          })
        }
        if (data.default_sku) {
          data_loads.push(put(addWizardData(data.default_sku)))
        }
      }

      if (data.is_signature || data.is_default_signature) {
        signatureBasket = true
        data_loads.push(put(setSignatureBasket(true)))
      }
    })

    if (preload.react.default_sku) {
      yield put(addWizardData(preload.react.default_sku))
      yield put(updateChoice("sku", { value: "NA" }))
    }

    if (preload.react.prefOrder) {
      yield put(setWizardPrefOrder(preload.react.prefOrder))
    }

    if (preload.react.coupon) {
      yield put(addCoupon(preload.react.coupon))
    }

    yield put(
      setExtraWizardParams({
        addons: preload.react.addons,
        base_product_url: preload.react.base_product_url,
        base_ss_price: preload.react.base_ss_price,
        default_sku: preload.react.default_sku,
        description: preload.react.description,
        filters: preload.react.filters,
        forHer: preload.react.forHer,
        forHim: preload.react.forHim,
        groupStyleCode: preload.react.groupStyleCode,
        hasDiamondSelector: preload.react.hasDiamondSelector,
        hasFaceComponentOptions: preload.react.hasFaceComponentOptions,
        hasImageOptions: preload.react.hasImageOptions,
        hasInventory: preload.react.hasInventory,
        hasYotpoGallery: preload.react.hasYotpoGallery,
        hiddenPrefs: preload.react.hiddenPrefs,
        hidePackaging: preload.react.hidePackaging,
        lookSubtitle: preload.react.lookSubtitle,
        masterStyleCode: preload.react.masterStyleCode,
        metalsIncludeSurchargeFor: preload.react.metals_include_surcharge_for,
        paneFilters: preload.react.paneFilters,
        price_and_promo: preload.react.price_and_promo,
        primaryTag: preload.react.primaryTag,
        priorityBadge: preload.react.priorityBadge,
        promo_double_jewls: preload.react.promo_double_jewls,
        promo_double_jewls_messaging:
          preload.react.promo_double_jewls_messaging,
        query_string: preload.react.query_string,
        seo_title: preload.react.seo_title,
        shareUrl: preload.react.shareUrl,
        title: preload.react.title,
      })
    )
  }
  yield all(data_loads)

  // Reset the choices when switching style codes to prevent issues with disabled choices
  // or other issues with missing engraving restrictions i.e. JWLN0301 (min_chars)
  if (!ssr) {
    yield put(clearChoices())
  }
  yield put(loadDefaults(preload.pds))

  if (!signatureBasket && preload.react.data.diamond) {
    yield put(updateChoice("e1", { disabled: true }))
    yield put(updateChoice("e2", { disabled: true }))
  }

  if (
    preload.react.notification &&
    Object.keys(preload.react.notification).length > 0
  ) {
    const { notification } = preload.react
    window.setTimeout(function () {
      appStoreV2.dispatch(addAlert(notification))
      submit_ga_event_click("Notification", "show", name, null, null, true)
    }, notification.delay * 1000)
  }

  if (!ssr) {
    yield put(setWizardLoading(""))
  }

  //Moved shipping info to react
  yield put(updateShipping(preload.react.shipping))

  // For non-NA regions, make a call to set delivery cookie immediately if the cookie doesn't yet exist
  // try {
  //   if (!is_na && Cookies.get(sl.shiptime_cookie_name) === undefined) {
  //     let element = document.getElementById("js_est_delivery_form")
  //     if (typeof element != "undefined" && element != null) {
  //       let formSubmitButton = document.getElementById("js_est_delivery_submit")
  //       formSubmitButton.click()
  //     }
  //   }
  // } catch (e) {
  //   console.error(e)
  // }

  perfMark("product:load_wizard:finish")

  if (parsedQuery.formUrl) {
    yield put(setExtraWizardParams({ formUrl: parsedQuery.formUrl }))
    yield put(setExtraWizardParams({ orderParams: parsedQuery.orderParams }))
  }
}
